.modal-outer {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
	width: 100%;
	z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-inner {
	background-color: #F3F4F4;
	position: relative;
    overflow: auto;
	padding: 20px 45px 20px 40px;
	z-index: 5;
	max-height: 70vh;
}

.modalFlex {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 730px;
	height: 70vh;
	padding: 0%;
	margin-top: 15px;
}