body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

input, button, textarea {
	outline: none;
}

/*
#FC5757- darkpink
#fc8989 - palepink
#4A4A4A - gray

#9B9B9B - lightGrayText
#4A4A4A - darkGrayText
*/
/*Colors*/
/*buttonStyles*/
/*mixins*/

.App {
  text-align: center;
	font-family: 'Poppins', sans-serif;
	max-width: 1440px;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

input {
	height: 48px;
	border: none;
	box-shadow: 0 2px 4px 0 rgba(66, 58, 58, .5);
	font-family: "Poppins", sans-serif;
}

.error {
	color: #FC5757;
}

/*Login Page*/

#loginWrapper {
  background-image: url(/static/media/loginHeroImage.dc355d2e.png);
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  margin: 0;
  display: flex;
  align-items: center;
	justify-content: center;
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
  font-size: 18px;
  margin: 0;
  padding: none; 
}

#signUpForm {
  margin: 0 auto;
  display: flex;
	align-content: center; 
  font-family: 'Poppins', sans-serif;
}

#signUpForm form {
  width: 316px;
  padding: 15px 25px 50px 25px;
  background-color: #f9f9f9;
  display: flex;
  flex-wrap: wrap; }

#signUpForm img {
	margin-bottom: 60px; }

#signUpForm label {
	margin-bottom: 5px;
}

#signUpForm input {
	align-content: left;
}

#signUpForm svg {
	height: 200px; 
}

.loginInput {
  width: 316px;
  margin-bottom: 25px;
  color: #9B9B9B;
  font-size: 18px;
	padding: 8px; 
}

.loginBtn {
  font-size: 24px;
  border-radius: 3px;
  background-color: white;
  border: 2px solid #FC5757;
  color: #FC5757;
  width: 315px;
  height: 50px;
	margin-bottom: 10px; 
}

.signUpBtn {
  font-size: 24px;
  border-radius: 3px;
  background-color: #FC5757;
  color: white;
  border: none;
  width: 315px;
	height: 50px; 
}
	
.signUpBtn a {
	color: white;
	text-decoration: none;
	font-size: 24px;
	width: 100%;
	height:100%;
}

#forgot {
	text-decoration: none;
	color: #9b9b9b;
	margin:-15px 0 90px;
	width: 316px;
	text-align: right;
}
#forgot:hover{
	color:blue;
}

/*Subscribers*/

#subsWrapper {
	float: right;
max-width: 920px;
height: 100%;
padding: 150px 110px 0 50px;
font-family: "Poppins", sans-serif;
margin-left:360px;
}
.redHR {
	border: .5px solid #FC5757;
}

.blackHR {
	border: .5px solid #4a4a4a
}

.subHeader {
display: flex;
flex-direction: row;
justify-content: flex-start;

}

.subsBtn {
	border: none;
	margin-right: 40px;
	font-size: 20px;
}

#subSearch {
	width: 440px;
	height: 32px;
	font-size: 16px;
}

.subCat {
display: flex;
flex-direction: row;
justify-content: space-between;
margin-top: 32px;
}

.subCat h3 {
background-color: #FC8989;
color: white;
padding: 5px;
}

.subInfo {
font-size: 12px;
display: flex;
flex-direction: row;
justify-content: space-between;
color: #4a4a4a;
}

/*sub columns*/
.columns {
	margin-bottom:10px;
	text-align: left;
}
.col1{
width: 179px;
}
.col2{
width: 110px;
}
.col3{
width: 125px;
}

/*AdminPage*/
#adminWrapper{
	display: flex;
	flex-direction: row;
	font-family: 'Poppins', sans-serif;
}
.nav {
	display: flex;
	flex-direction: column;
	box-shadow: 0 2px 4px 0 rgba(66, 58, 58, .5);
	width: 360px;
	height:100vh;
	position: fixed;
	z-index: 2;
	background-color: #fff;
}

.mainCont {
	margin-left: 360px;
}

.adminPageLink {
	font-size: 20px;
	letter-spacing: .95px;
	line-height: 30px;
	text-decoration: none;
	font-family: 'Poppins', sans-serif;
	text-align: right;
	padding:15px;
}


.navTitle {
	border-radius: 50%;
	width: 100px;
	background-color: #F3F4F4;
	height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 24px;
	font-weight: 500;
	letter-spacing: .14px;
	font-family: 'Poppins', sans-serif;
	box-shadow: 0 2px 4px 0 rgba(66, 58, 58, .5);
	margin: 55px 0 10px 130px;
}

.navEmail {
	text-align: center;
	margin-bottom: 40px;
}

/*This-week view*/

#thisWeekWrapper {
	width: 100%;
	height: 100%;
	margin-left:360px;
	padding-left: 48px;
	background-color: #F3F4F4;
	font-family: 'Poppins', sans-serif;
	padding-bottom: 10%;
}

.flexWrapper {
display: flex;
flex-direction: row;
}

.message{
	margin-top: 15px;
	font-size: 12px;
	line-height: 18px;
	letter-spacing: .12px;
	color: #4a4a4a;
	width: 400px;
	border-radius: 7px;
	border: none;
	padding: 5px;
}

.week-view-btns {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: calc(100% - 24px);
	margin-right: 24px;
}

.editBtn {
	color: #FC5757;
	background-color: #F3F4F4;
	border: 2px solid #FC5757;
	border-radius: 3px;
	height: 32px;
	width: 80px;
	margin: 0 10px 10px 0;
	font-size: 14px;
	font-weight: 500;
}

.removeBtn {
	color: #F3F4F4;
	background-color: #FC5757;
	border: 2px solid #FC5757;
	border-radius: 3px;
	height: 32px;
	width: 80px;
	margin: 0 0 10px 0;
	font-size: 14px;
	font-weight: 500;
}


#liveIcon {
	position: absolute;
	margin-top: 20px;
	margin-left: 60px;
}

#phoneIcon {
	position: absolute;
	margin-left: 20px;
	margin-top: 20px;
}

.check {
	background-color: #FC5757;
}

#searchIcon {
	position: absolute;
	margin-left: -40px;
	background: none;
	border: none;
}

#editMsg {
	margin-left: 330px
}

#thisWeekWrapper h1, h2, h3, h4, h5, p{
	color: #4a4a4a;
	
}

#thisWeekWrapper h1 {
	font-size: 44px;
	font-weight: 300;
	letter-spacing: .44px;
	line-height: 65px;
}

#thisWeekWrapper h2 {
	padding-top: 15px;
	font-weight: 600;
	font-size: 24px;
	margin-top: 10px;
}

#thisWeekWrapper h3 {
	font-size: 18px;
	font-weight: 500;
	margin-top: 10px;
	margin-bottom: 5px;
}

.text14LightBlack {
	font-weight: 300;
	font-size: 14px;
	line-height: 18px;
	letter-spacing: .29px;
	margin-top: 8px;
}

.text14MedBlack {
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	letter-spacing: .29px;
	margin-top: 8px;
}

.text12MedBlack {
	font-size: 12px;
	font-weight: 500;
	color: #4a4a4a;
	letter-spacing: .24px;
	margin-top:10px; 
}

.text12LightGrey {
	font-size: 12px;
	font-weight: 300;
	color: #4A4A4A;
	letter-spacing: .29px;
	line-height: 18px;
	margin-top: 10px;
}

.text14LightGrey {
	font-size: 12px;
	font-weight: 300;
	color: #9b9b9b;
	letter-spacing: .24px;
	margin-top:10px; 
}

.text10LightGrey {
	font-weight: 300;
	color: #9b9b9b;
	font-size: 10px;
	letter-spacing: .24px;
	line-height: 16px;
}

.text10LightBlack {
	color: #4a4a4a;
	display: inline;
	max-width: 185px;
	max-height: 20px;
	overflow: hidden;
	font-size: 10px;
	letter-spacing: .24px;
	line-height: 16px;
}

.text10MedBlack {
	font-weight: 500;
	color: #4a4a4a;
	display: inline;
	font-size: 10px;
	letter-spacing: .24px;
	line-height: 16px;
}

.workoutDesc {
	font-size: 10px;
	letter-spacing: .24px;
	color: #9b9b9b;
	line-height: 16px;
	margin-top: 10px;
	max-width: 320px; 
}

.previewBox {
	width: 300px;
	min-height: 320px;
	background-color: #fff;
	padding: 20px;
	margin: 2px 24px 20px 0
}

.previewBox a {
	text-decoration: none;
	color: #4a4a4a;
	font-weight: 300;
	font-size: 10px;
	letter-spacing: .24px;
	line-height: 16px;
}

.noneSelected {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.exerciseGroup {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.gymratTitle {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.dumbell {
	margin-right: 10px;
	width: 40px;
	height: 40px;
}

.exerciseIMG {
	width: 40px;
	height: 40px;
}

.circuitHR {
	border: 1px solid #4a4a4a;
	border-radius: 1px;
}

.redHR {
	border: 1px solid #FC5757;
	border-radius: 1px;
}

.exerciseHR {
	border: .5px solid #9b9b9b;
	border-radius: 1px;
}

.atHomeVid {
	width: 320px;
	margin: -10px -10px 0;
}

.moreBtn {
	color: #FC5757;
	border: none;
	font-size: 12px;
	letter-spacing: .29px;
	line-height: 18px;
	margin-left:260px;
	background-color: #fff;
	z-index: 3;
}

.modal-inner {
	position: fixed;
	z-index: 5;
}

#modalSave {
	margin-left: 15px;
}

/*Calendar*/

#calendarWrapper {
	margin-left: 360px;
	background-color: #F3F4F4;
	padding-left: 80px;
	width: 100%;
	font-size: 40px;
	font-family: "Poppins", sans-serif;
}

#month {
	color: #4a4a4a;
	letter-spacing: .96px;
	line-height: 60px;
	display: inline-block;
	margin: 0px 10px 15px;
	width: 200px;
	text-align: center;
	}

.monthIcon {
	width: 24px;
}
.weeks {
	width:400px;
  text-align: center;
}

.weekView {
  display: flex;
  flex-direction: row;
  margin:5%
}

.weekBtn {
	width: 400px;
	height: 64px;
	line-height: 64px;
	margin-top: 4px;
	border-radius: 3px;
	letter-spacing: .4px;
}

/*Workout Pages*/

#workoutWrapper {
	margin-left: 360px;
	padding: 10px 48px;
	font-family: "Poppins", sans-serif;
	background-color: #F3F4F4;
	width: 90vw;
	max-width: 1080px;
	height: 100%;
	min-height: 100vh;
}

#workoutWrapper hr {
	width: 100%;
	border:.5px solid #FC5757;
}

#createOptions {
	display: flex;
	flex-direction: row;
	font-size: 24px;
	letter-spacing: .24px;
	line-height: 35px;
	/* margin-bottom: 5px; */
}

#createBtn {
	width: 400px;
	height: 64px;
	background-color: #FC5757;
	color:#fff;
	line-height: 64px;
	border-radius: 5px;
	font-size: 24px;
	margin-top: 24px;
}

.workoutSearch {
	margin-top: 28px;
	width: 440px;
	height: 32px;
	font-size: 16px;
	padding: 4px;
	border-radius: 5px;
	font-weight: 300;
	letter-spacing: 1.06px;

	border: none;
	box-shadow: 0 2px 4px 0 rgba(66, 58, 58, .5);
}

.workoutDisplay {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-top: 48px;
}

/*Create modal */

.createForm {
	color: #4a4a4a;
	width: 500px;
}

#create24BlackNorm {
	font-size: 24px;
	color: #4a4a4a;
	letter-spacing: .24px;
	line-height: 35px;
	margin-bottom: 40px;
}

.createForm input, .createDesc {
	width: 100%;
	border: 2px solid #9B9B9B;
	border-radius: 4px;
	color: black;
	font-weight: 200;
	margin-top: 10px;
	line-height: 22px;
	font-size: 18px;
	padding:3px;
	font-family: 'Poppins', sans-serif;
	height: 25px;
}

.createDesc {
	min-height: 100px;
}

.modalSmallBtn {
	font-size: 10px;
	letter-spacing: .1px;
	line-height: 11px;
	color: #4a4a4a;
}

.createTitle {
	font-size: 12px;
}

.circBtn {
	color: #FC5757;
	font-size: 24px;
	letter-spacing: .14px;
	line-height: 24px;
	font-weight: 300;
	background-color: #F3F4F4;
	border: none;
	margin-top: 16px;
	font-family: 'Poppins', sans-serif;
}

#circAdd {
	margin-right: 5px;
	margin-bottom: -2px;
}

button, .weekBtn, .monthIcon {
	cursor: pointer;
}

button:focus {
	outline: none;
}

.createAddRem {
	border: none;
	font-size: 10px;
	letter-spacing: .1px;
	font-weight: 400;
	color: #4a4a4a;
	background-color: #F3F4F4;
}

#circSave {
	height: 32px;
	width: 80px;
	line-height: 24px;
	color: #FC5757;
	background-color: #F3F4F4;
	border: 2px solid #FC5757;
	margin: 0 0 12px 16px;
	border-radius: 3px;
}

#circCanc {
color: #4a4a4a;
font-weight: 200;
}

.circClose {
	margin-left: 220px;
}

#userPortal {
	width: 316px;
  padding: 15px 25px 50px 25px;
  background-color: #f9f9f9;
  display: flex;
	flex-wrap: wrap;
	font-family: 'Poppins', sans-serif;
	color: #4a4a4a;
}
	
.userTitle {
	margin: 0 auto;
	margin-top: 30px;
	margin-bottom: 10px;
}

#userEmail {
	text-align: center;
	margin: 15px 0 30 0;
}

.userPortalInfo {
	font-size: 14px;
	font-weight: 400;
	margin-bottom: 25px;
	line-height: 150%;
	text-align: center;
}

#planChange {
	text-decoration: none;
	margin-bottom: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.registration {
	margin-bottom: 10px;
}

.regForm {
	color: #FC8989;
	font-weight: 600;
}

.logout {
	font-size: 12px;
  border-radius: 3px;
  background-color: white;
  border: 2px solid #FC5757;
  color: #FC5757;
  width: 80px;
	height: 20px;
	margin-left: 37%;
	margin-bottom: 10px; 
}

#adminLogout {
	margin: 0 auto;
	width: 100%;
	height: 30px;
	border-radius: 0;
}

#passToLogin {
	text-decoration: none;
	text-align: center;
	line-height: 50px;
}

.portalDiv {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.icon {
	background-color: inherit;
	border: none;
}

.display-routine {
	width: 500px;
	margin-top: 15px;
}

/* Delete/Remove Modal */

.deleteModal h1 {
	font-size: 25px !important;
	line-height: 35px !important;
	display: inline-block;
}

.deleteModal span {
	color: black !important;

}
.deleteModal button {
	border-radius: 3px;
	height: 32px;
	width: 80px;
	margin: 25px 25px 5px 0;
	font-size: 14px;
	font-weight: 500;
}

.deleteBtn1 {
	color: #F3F4F4;
	background-color: #FC5757;
	border: 2px solid #FC5757;
}
.deleteBtn2 {
	color: #FC5757;
	background-color: #F3F4F4;
	border: 2px solid #FC5757;
}

.createBtmBtn {
	height: 32px;
	width: 80px;
	margin: 5px;
}

.closeBtn {
	position: absolute;
	top: 5px;
	right: 5px;
	background: none;
	border: none;
	font-size: 24px;
	color: #4a4a4a;
}

.close-outer {
	margin-left: -45px;
	margin-top: -30px;
	z-index: 10;
	height: 70vh;
}

.weekSetWrapper {
	display: flex;
	flex-direction: row;
	/* justify-content: center; */
	align-items: center;

}

.setWeek {
	background: none;
	border: 2px solid #4a4a4ab9;
	border-radius: 5px;
	font-size: 25px;
	color: #4a4a4ab9;
	padding: 5px;
	height: 45px;
	margin-left: 107px;
}

.atHomeTitle {
	margin-top: 10px;
}

.titleP {
	font-weight: 600;
	padding-bottom: 5px;
}
.modal-outer {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
	width: 100%;
	z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-inner {
	background-color: #F3F4F4;
	position: relative;
    overflow: auto;
	padding: 20px 45px 20px 40px;
	z-index: 5;
	max-height: 70vh;
}

.modalFlex {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 730px;
	height: 70vh;
	padding: 0%;
	margin-top: 15px;
}
